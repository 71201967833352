import axios from './axios.js';
import Qs from 'qs';
// import { Dialog } from 'vant';
import { ElMessage } from 'element-plus'
import { test } from '@/config/index';

var base_url = "http://106.12.82.74:8926"
var base2_url = "http://1.13.187.253:9002"
var base3_url = "http://1.13.187.253:9002"
var base4_url = "http://82.156.85.106:9018"
// var file_url = "http://119.45.171.34:9001"
if (test) {
    console.log("开发环境")
    // base_url = config_url || 'http://192.168.0.30:8080' //李健洋
    // base_url = config_url || 'http://192.168.0.114:8080' //韩增
    // base_url = config_url || 'http://1.13.187.253:8080' //测试
    // base2_url = config2_url || 'http://192.168.0.127:9002' //王嘉怡
    base_url = config_url || 'http://1.13.187.253:8080' //正式
    base2_url = config2_url || 'http://1.13.187.253:9002' //正式
    base3_url = config3_url || 'http://1.13.187.253:9001' //正式
    // base2_url = config2_url || 'http://192.168.1.19:9002' //shiyue
    // base3_url = config3_url || 'http://192.168.1.19:9001' //shiyue
    
    base4_url = config4_url || 'http://82.156.85.106:9018' //正式
    // base2_url = config_url || 'http://192.168.1.41:9002' //杨
} else {
    console.log("生产环境")
    base_url = config_url || 'http://ceds.pub:8080' //正式
    base2_url = config2_url || 'http://1.13.187.253:9002' //正式
    base3_url = config3_url || 'http://1.13.187.253:9001' //正式
    base4_url = config4_url || 'http://82.156.85.106:9018' //正式
}

var url = {
    echarts: "/static/echarts/index.html?x=" + Math.random(),
    // login: base_url + "/users/login",
    info: base_url + "/users/info",
    logout: base_url + "/users/logout",




    search_by_attribute: base_url + "/company/search_by_attribute",
    search_by_condition: base_url + "/company/search_by_condition",
    hk_search_by_attribute: base_url + "/company/hk_search_by_attribute",
    hk_search_by_condition: base_url + "/company/hk_search_by_condition",
    third_search_by_attribute: base_url + "/company/third_search_by_attribute",
    third_search_by_condition: base_url + "/company/third_search_by_condition",
    fund_search_by_attribute: base_url + "/company/fund_search_by_attribute",
    fund_search_by_condition: base_url + "/company/fund_search_by_condition",
    bond_search_by_attribute: base_url + "/company/bond_search_by_attribute",
    bond_search_by_condition: base_url + "/company/bond_search_by_condition",

    third_attribute_type: base_url + "/company/third_attribute_type",
    bond_attribute_type: base_url + "/company/bond_attribute_type",

    search_title: base_url + "/announcement_search/search_title",
    get_title_by_cache_id: base_url + "/announcement_search/get_title_by_cache_id",
    export_title_by_cache_id: base_url + "/announcement_search/export_title_by_cache_id",
    save_title: base_url + "/announcement_search/save_title",

    search_scheme_by_type: base_url + "/announcement_search/search_scheme_by_type",

    search_content: base_url + "/announcement_search/search_content",
    save_content: base_url + "/announcement_search/save_content",

    search_word_count_corpus: base_url + "/announcement_search/search_word_count_corpus",

    search_word_count: base_url + "/announcement_search/search_word_count",
    save_word_count: base_url + "/announcement_search/save_word_count",
    get_word_count_by_cache_id: base_url + "/announcement_search/get_word_count_by_cache_id",
    export_word_count_by_cache_id: base_url + "/announcement_search/export_word_count_by_cache_id",

    search_sentence: base_url + "/announcement_search/search_sentence",
    get_sentence_by_cache_id: base_url + "/announcement_search/get_sentence_by_cache_id",
    export_sentence_by_cache_id: base_url + "/announcement_search/export_sentence_by_cache_id",
    save_sentence: base_url + "/announcement_search/save_sentence",

    search_paragraph: base_url + "/announcement_search/search_paragraph",
    get_paragraph_by_cache_id: base_url + "/announcement_search/get_paragraph_by_cache_id",
    export_paragraph_by_cache_id: base_url + "/announcement_search/export_paragraph_by_cache_id",
    save_paragraph: base_url + "/announcement_search/save_paragraph",

    information_search_title: base_url + "/information_search/search_title",
    get_search_title_by_cache_id: base_url + "/information_search/get_search_title_by_cache_id",
    export_search_title_by_cache_id: base_url + "/information_search/export_search_title_by_cache_id",
    information_save_title: base_url + "/information_search/save_title",

    information_search_content: base_url + "/information_search/search_content",
    get_search_content_by_cache_id: base_url + "/information_search/get_search_content_by_cache_id",
    export_search_content_by_cache_id: base_url + "/information_search/export_search_content_by_cache_id",
    information_save_content: base_url + "/information_search/save_content",

    information_search_word_count: base_url + "/information_search/search_word_count",
    information_get_word_count_by_cache_id: base_url + "/information_search/get_word_count_by_cache_id",
    information_export_word_count_by_cache_id: base_url + "/information_search/export_word_count_by_cache_id",
    information_save_word_count: base_url + "/information_search/save_word_count",

    information_search_scheme_by_type: base_url + "/information_search/search_scheme_by_type",

    information_search_sentence: base_url + "/information_search/search_sentence",
    information_get_sentence_by_cache_id: base_url + "/information_search/get_sentence_by_cache_id",
    information_export_sentence_by_cache_id: base_url + "/information_search/export_sentence_by_cache_id",
    information_save_sentence: base_url + "/information_search/save_sentence",

    information_search_paragraph: base_url + "/information_search/search_paragraph",
    information_get_paragraph_by_cache_id: base_url + "/information_search/get_paragraph_by_cache_id",
    information_export_paragraph_by_cache_id: base_url + "/information_search/export_paragraph_by_cache_id",
    information_save_paragraph: base_url + "/information_search/save_paragraph",

    search_feature_corpus: base_url + "/feature_extraction/search_feature_corpus",
    search_feature: base_url + "/feature_extraction/search_feature",
    get_feature_by_cache_id: base_url + "/feature_extraction/get_feature_by_cache_id",
    export_feature_by_cache_id: base_url + "/feature_extraction/export_feature_by_cache_id",
    save_feature: base_url + "/feature_extraction/save_feature",


    get_factor_by_dt: base_url + "/factor_transformation/get_factor_by_dt",
    search_factor: base_url + "/factor_transformation/search_factor",
    get_factor_by_cache_id: base_url + "/factor_transformation/get_factor_by_cache_id",
    export_factor_by_cache_id: base_url + "/factor_transformation/export_factor_by_cache_id",
    save_factor: base_url + "/factor_transformation/save_factor",

    announcement_search_schema: base2_url + "/yk_delete_task_mobule/comm_api_server/comm_task_scheme_list_query",
    // announcement_result_by_plan_id: base_url + "/programme_management/announcement_result_by_plan_id",
    announcement_result_by_plan_id: base3_url + "/yk_task_module/programme_management/announcement_result_by_plan_id",
    export_announcement_result_by_plan_id: base_url + "/programme_management/export_announcement_result_by_plan_id",
    // export_announcement_result_by_plan_id: file_url + "/yk_task_module/programme_management_export/announcement_result_by_plan_id",
    announcement_delete_schema: base_url + "/programme_management/announcement_delete_schema",
    announcement_use_schema: base_url + "/programme_management/announcement_use_schema",

    // feature_search_schema: base_url + "/programme_management/feature_search_schema",
    feature_search_schema: base2_url + "/yk_delete_task_mobule/comm_api_server/feature_search_schema",
    feature_result_by_plan_id: base3_url + "/yk_task_module/programme_management/feature_result_by_plan_id",
    export_feature_result_by_plan_id: base_url + "/programme_management/export_feature_result_by_plan_id",
    // export_feature_result_by_plan_id: file_url + "/yk_task_module/programme_management_export/feature_result_by_plan_id",
    feature_delete_schema: base_url + "/programme_management/feature_delete_schema",
    feature_use_schema: base_url + "/programme_management/feature_use_schema",

    // factor_search_schema: base_url + "/programme_management/factor_search_schema",
    factor_search_schema: base2_url + "/yk_delete_task_mobule/comm_api_server/factor_search_schema",
    factor_result_by_plan_id: base3_url + "/yk_task_module/programme_management/factor_result_by_plan_id",
    export_factor_result_by_plan_id: base_url + "/programme_management/export_factor_result_by_plan_id",
    // export_factor_result_by_plan_id: file_url + "/yk_task_module/programme_management_export/factor_result_by_plan_id",
    factor_delete_schema: base_url + "/programme_management/factor_delete_schema",
    factor_use_schema: base_url + "/programme_management/factor_use_schema",

    info_search_schema: base2_url + "/yk_delete_task_mobule/comm_api_server/comm_task_info_scheme_list_query",
    info_result_by_plan_id: base3_url + "/yk_task_module/programme_management/info_result_by_plan_id",
    export_info_result_by_plan_id: base_url + "/programme_management/export_info_result_by_plan_id",
    // export_info_result_by_plan_id: file_url + "/yk_task_module/programme_management_export/info_result_by_plan_id",
    info_delete_schema: base_url + "/programme_management/info_delete_schema",
    info_use_schema: base_url + "/programme_management/info_use_schema",

    announcement_update_my_type: base_url + "/programme_management/announcement_update_my_type",
    info_update_my_type: base_url + "/programme_management/info_update_my_type",
    feature_update_my_type: base_url + "/programme_management/feature_update_my_type",
    factor_update_my_type: base_url + "/programme_management/factor_update_my_type",


    get_thesis_list: base_url + "/demonstration_model/get_thesis_list",
    get_thesis_info: base_url + "/demonstration_model/get_thesis_info",
    get_custom_model_list: base_url + "/demonstration_model/get_custom_model_list",
    delete_custom_model: base_url + "/demonstration_model/delete_custom_model",
    save_custom_model: base_url + "/demonstration_model/save_custom_model",
    get_custom_model_by_id: base_url + "/demonstration_model/get_custom_model_by_id",

    get_factor: base_url + "/demonstration_model/get_factor",
    demonstration_run: base_url + "/demonstration_model/demonstration_run",
    custom_data_demonstration_work: base_url + "/demonstration_model/custom_data_demonstration_work",
    get_factor_value_by_id: base_url + "/factor_transformation/get_factor_value_by_id",

    get_sample_data: base_url + "/demonstration_model/get_sample_data",
    file_upload: base_url + "/demonstration_model/file_upload",

    save_variable: base_url + "/demonstration_model/save_variable",
    get_variable: base_url + "/demonstration_model/get_variable",

    get_basic_accounting_problem_list: base_url + "/demonstration_model/get_basic_accounting_problem_list",
    get_basic_accounting_problem_by_id: base_url + "/demonstration_model/get_basic_accounting_problem_by_id",
    delete_variable: base_url + "/demonstration_model/delete_variable",



    level_of_market_impact: base_url + "/vcase/level_of_market_impact",
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
    login: base2_url + "/yk_delete_task_mobule/vue_computer_service_controller/login",
    file_up_load: base2_url + "/yk_delete_task_mobule/vue_computer_service_controller/file_up_load",
    ajax_add_user: base2_url + "/yk_delete_task_mobule/vue_computer_service_controller/ajax_add_user",
    ajax_apply_for_extension: base2_url + "/yk_delete_task_mobule/vue_computer_service_controller/ajax_apply_for_extension",
    
    get_menu_list: base2_url + "/yk_delete_task_mobule/comm_menu_server/get_menu_list",

    change_password: base2_url + "/yk_delete_task_mobule/vue_computer_service_controller/change_password",
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    sub_power_search: base3_url + "/yk_task_module/comm_user_api_server/sub_power_search",
    sub_power_add: base3_url + "/yk_task_module/comm_user_api_server/sub_power_add",
    search_sale_menu_list: base3_url + "/yk_task_module/comm_wx_pay_api_server/search_sale_menu_list",
    search_qr_code: base3_url + "/yk_task_module/comm_wx_pay_api_server/search_qr_code",
    wx_is_pay: base3_url + "/yk_task_module/comm_wx_pay_api_server/wx_is_pay",
    search_user_ms: base3_url + "/yk_task_module/comm_wx_pay_api_server/search_user_ms",
    get_file_url: base3_url + "/yk_task_module/comm_user_api_server/get_file_url",
    get_sentence_by_cache_id: base3_url + "/yk_task_module/comm_user_api_server/get_sentence_by_cache_id",
    get_word_count_by_cache_id: base3_url + "/yk_task_module/comm_user_api_server/get_word_count_by_cache_id",
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    first_menu_search: base4_url + "/yk_fast_module/comm_fast_api_server/first_menu_search",
    secondly_menu_search: base4_url + "/yk_fast_module/comm_fast_api_server/secondly_menu_search",
    tab_menu_search: base4_url + "/yk_fast_module/comm_fast_api_server/tab_menu_search",
    // tab_menu_search: 'http://192.168.1.41:9001/yk_fast_module/comm_fast_api_server/tab_menu_search',
    // get_excel_and_massage: base4_url + "/yk_fast_module/comm_fast_api_server/get_excel_and_massage",
    get_massage: base4_url + "/yk_fast_module/comm_fast_api_server/get_massage",
    get_excel: base4_url + "/yk_fast_module/comm_fast_api_server/get_excel",
    // get_massage: "http://192.168.1.54:9018/yk_fast_module/comm_fast_api_server/get_massage",
    // get_excel: "http://192.168.1.54:9018/yk_fast_module/comm_fast_api_server/get_excel",

    get_number_search:  base3_url + "/yk_task_module/comm_ask_api_server/get_number_search", // 获取方案个数
    // do_delete_task:  base3_url + "/yk_task_module/comm_ask_api_server/do_delete_task", // 删除方案
    do_delete_task:  base2_url + "/yk_delete_task_mobule/comm_api_server/do_delete_tesk", // 删除方案
    search_user_dx_create: base2_url + "/yk_delete_task_mobule/comm_api_server/search_user_dx_create", // 存储空间请求
    get_tztq_jz: base3_url +"/yk_task_module/comm_ask_api_server/get_tztq_jz",//特征提取 -》 智能提取
    get_tztq_tj_jg: base3_url +"/yk_task_module/comm_ask_api_server/get_tztq_tj_jg",//特征提取 -》 智能提取提交

}

var obj = {
    url,
    post(config) {
        var p_url = url[config.url] || config.url
        config.data = config.data || {}
        // config.data.creater = "1"
        var set = {
            headers: {
                // HeaderKey: 1
            }
        }
        if (config.file) {
            set.responseType = "blob"
        }
        if (config.url != 'login' && localStorage.Authorization) {
            set.headers.Authorization = localStorage.Authorization
        }
        axios.post(p_url, config.data, set)
            .then(function(res) {
                if (config.file) {
                    var blob = new Blob([res.data], {
                        type: {
                            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                        } [config.fileType]
                    });
                    window.open(window.URL.createObjectURL(blob))
                    return
                }
                if (res.data || res.data.toString) {
                    res.data.message = res.data.message || res.data.msg
                    if (res.data.code != 200 && res.data.code != "00000") {
                        config.no_message || ElMessage({
                            message: "提示:" + res.data.message,
                            type: 'error',
                        })
                        if (config.fail) {
                            config.fail(res.data)
                            return
                        }
                        if (res.data.code == 502 && "login" != config.url) {
                            if (test) {
                                return
                            }
                            localStorage.Authorization = ""
                            localStorage.ceds_user = ""
                            location.href = "/login"
                        }
                        /*Notification.error({
                            title: '提示',
                            message: res.data.msg
                        })*/
                        return
                    }
                    /*Notification.success({
                        title: '提示',
                        message: res.data.msg
                    })*/
                    config.success && config.success(res.data)
                    return
                }
                ElMessage({
                    message: "接口返回为空",
                    grouping: true,
                    type: 'error',
                })
            })
            .catch(function(error) {
                var err_res = {
                    message: "接口返回失败",
                }
                if (error.response) {
                    err_res.message = {
                        "Internal Server Error": "内部服务器错误",
                    } [error.response.statusText] || (error.response.data && error.response.data.Message) || "接口返回失败"
                } else {
                    err_res.message = {
                        "Network Error": "网络连接失败",
                    } [error.message] || "页面程序错误"
                }
                config.no_message || ElMessage({
                    message: "提示:" + err_res.message,
                    type: 'error',
                })
                console.log("error", [error])
                if (config.fail) {
                    config.fail(err_res)
                    return
                }
                if (!error.response) {
                    console.error(error.stack)
                }
            });
    },
    get(config) {
        var p_url = url[config.url] || config.url
        config.data = config.data || {}
        /*
            ID: "114"
            account: "001"
            email: "yk@163.com"
            msg: "登录成功"
            name: "liu"
            school: "产品支持"
            telephone: "18640195989"
        */
        if (this.store.user.ID) {
            config.data.school = this.store.user.school
            config.data.account = this.store.user.account
            config.data.name = this.store.user.name
        }
        axios.get(p_url, config.data)
            .then(function(res) {
                if (res.data || res.data.toString) {
                    if (res.data.errCode) {
                        if (config.fail) {
                            config.fail(res.data)
                            return
                        }
                        ElMessage({
                            message: "提示:" + res.data.msg,
                            type: 'error',
                        })
                        /*Notification.error({
                            title: '提示',
                            message: res.data.msg
                        })*/
                        return
                    }
                    /*Notification.success({
                        title: '提示',
                        message: res.data.msg
                    })*/
                    config.success && config.success(res.data)
                    return
                }
                ElMessage({
                    message: "接口返回为空",
                    grouping: true,
                    type: 'error',
                })
            })
            .catch(function(error) {
                if (config.fail) {
                    config.fail({
                        errCode: 500,
                        msg: "接口返回失败"
                    })
                } else {
                    ElMessage({
                        message: "接口返回失败",
                        grouping: true,
                        type: 'error',
                    })
                }
                config.error && config.error({
                    url: p_url
                })
            });
    },
    url_obj() {
        return Qs.parse(location.search.replace("?", ""))
    },
    url_str(obj, _h = "") {
        return _h + Qs.stringify(obj)
    },
    get_url_argument(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
        var r = decodeURI(window.location.search).substr(1).match(reg)
        if (r != null) return unescape(r[2])
        return null
    },
    get_url_argument_obj() {
        var obj = {}
        var reg = new RegExp('([^\\?|&]*)=([^&]*)', 'g')
        var r = decodeURI(window.location.search).match(reg) || []
        for (var i = 0; i < r.length; i++) {
            var t = r[i].split('=')
            obj[t[0]] = t[1]
        }
        return obj
    },
    url_passer(obj, key) {
        var _this_ = this
        var returnStr = ''
        switch (typeof obj) {
            case 'string':
            case 'number':
            case 'boolean':
                returnStr += '&' + key + '=' + obj
                break
            default:
                for (var i in obj) {
                    if (typeof obj[i] !== 'function') {
                        if (key && key !== '?' && key !== '&') {
                            returnStr += _this_.url_passer(obj[i], key + '[' + i + ']')
                        } else {
                            returnStr += _this_.url_passer(obj[i], i)
                        }
                    }
                }
                break
        }
        switch (key) {
            case '&':
                return returnStr.replace(/^./, '')
            case '?':
                returnStr = "?" + returnStr
        }
        /*if (key === '?') {
            return returnStr.replace(/^./, '?')
        } else if (key === '&') {
            return returnStr.replace(/^./, '')
        }*/
        return returnStr
    },
    column_prop_filter(item, first = [], not = []) {
        var arr = []
        var keys = Object.keys(item)
        // console.log("column_prop_filter",keys,first,not)
        arr = first.filter((a_item) => {
            return keys.indexOf(a_item) != -1
        })
        arr = arr.concat(keys.filter((a_item) => {
            return [...first, ...not].indexOf(a_item) == -1
        }))
        return arr
    },
    list_page_filter(list, page = 1, page_size = 10) {
        var arr = [...list]
        arr = arr.slice((page - 1) * page_size, page * page_size)
        return arr
    },
    arr_a_filter_b(a, b = []) {
        return a.filter((a_item) => {
            return b.indexOf(a_item) == -1
        });
    },
    str_filter(val, _config, recursion) {
        _config = {
            s: 6,
            e: 4,
            ..._config
        }
        if (!val) {
            return val
        }
        return val.substring(0, _config.s) + "..." + val.substring(val.length - _config.e)
    },
    /*testFun(){
        console.log("testFun",this)
    }*/
}
// String.prototype._ajax = obj
// String.prototype._testFun = obj.testFun

export default obj