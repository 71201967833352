<template>
    <el-config-provider :locale="zhCn">
        <!-- ({{_ajax.store.user}}) -->
        <router-view v-if="_ajax.store.re_load" />
    </el-config-provider>
</template>
<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import { test } from '@/config/index';

export default {
    name: 'app',
    data() {
        return {
            zhCn,
            form: {},
            isRouterAlive: true,
            re_load: true
        }
    },
    mounted() {
        /*if (test) {
            this._ajax.store.user.ID = "114"
            this._ajax.store.user.account = "001"
            this._ajax.store.user.email = "yk@163.com"
            this._ajax.store.user.msg = "登录成功"
            this._ajax.store.user.name = "liu"
            this._ajax.store.user.school = "产品支持"
            this._ajax.store.user.telephone = "18640195989"
        }*/
        this._ajax.store.user = JSON.parse(localStorage.ceds_user || '{"permissions_group":[]}')
        this.infoFun()
        setInterval(this.infoFun, 5 * 60 * 1000)
        /*var _this = this
        this._ajax.reLoad = function(argument) {
            _this.re_load = false
            _this.$nextTick(() => {
                _this.re_load = true
            })
        }*/
    },
    methods: {
        infoFun() {
            if (localStorage.Authorization && this.$route.name && this.$route.name != "login") {
                this._ajax.post({
                    url: "info",
                    success: (res) => {
                        this._ajax.store.user = res.data
                        localStorage.ceds_user = JSON.stringify(res.data)
                    }
                })
            } else if (this.$route.name && this.$route.name != "login") {
                this.routerPush("login")
            }
        }
    },
    watch: {
        $route(a, b) {
            if (!b.name) {
                // console.log("watch", a, b)
                this.infoFun()
            }
        }
    }
}
</script>
<style lang="scss">
</style>