import { createApp } from 'vue'
import '@/assets/css/element.scss'
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import ajax from "@/ajax"
import { storeInit } from '@/store/index.js'

import '@/assets/css/base.scss'

var app = createApp(App)
app.use(ElementPlus)
app.use(createPinia())
app.use(router)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

ajax.store = storeInit()
app.config.globalProperties._ajax = ajax
app.config.globalProperties.routerPush = function(name, data) {
    if (!name) {
        return
    }
    if (this._ajax.store.user.user_category == '机构') {
        if ([
                //
                "announcement_Third",
                "announcement_Bond",
                "announcement_HongKongShares",
                "announcement_Fund",
                "announcement_Supervise",
                "information_F",
            ].indexOf(name) != -1) {
            this.$message({
                type: 'warning',
                message: "您目前暂无此功能权限。"
            })
            return
        }
    }
    this.$router.push({
        name: name,
        query: data
    });
}
app.config.globalProperties.permissionsTest = function(permissions, type) {
    if (!permissions && type != "login") {
        return
    }
    if (!this._ajax.store.user.permissions_group || !this._ajax.store.user.permissions_group.length) {
        return
    }
    var test = this._ajax.store.user.permissions_group.indexOf(permissions) != -1
    if (type == "test") {
        return test
    }
    /*console.log("permissions",permissions)
    console.log("test",test)
    return*/
    if (!test || type == "login") {
        if (this._ajax.store.user.permissions_group.indexOf("text_library") != -1) {
            this.routerPush("text_library", {
                v1: "上市公司",
                v2: "公司治理",
            })
            return
        }
        if (this._ajax.store.user.permissions_group.indexOf("content_search") != -1) {
            this.routerPush("announcement_retrieval")
            return
        }
        if (this._ajax.store.user.permissions_group.indexOf("demonstration_model") != -1) {
            this.routerPush("empirical_model")
            return
        }
        if (this._ajax.store.user.permissions_group.indexOf("thesis_check") != -1) {
            this.routerPush("programme_announcement")
            return
        }
        if (this._ajax.store.user.permissions_group.indexOf("task_announcement") != -1) {
            this.routerPush("programme_announcement")
            return
        }
        this.routerPush("programme_announcement")
    }
}
app.config.globalProperties.userQuit = function(name, data) {
    ajax.post({
        url: "logout",
        success: (res) => {
            localStorage.Authorization = ""
            localStorage.ceds_user = ""
            location.href = "/login"
        }
    })
}
app.config.globalProperties._textOverTest = function(form, key, num) {
    if (form[key] && form[key].length > num) {
        this.$message({
            type: 'success',
            message: "文字过长"
        })
        form[key] = form[key].slice(0, num)
    }
}
app.config.globalProperties._textKTest_A = function(form, key, k) {
    var arr = form[key].match(k)
    var str = arr.join("")
    if (form[key] != str) {
        this.$message({
            type: 'success',
            message: "文字被整理"
        })
    }
    form[key] = str
}




app.mount('#app')