import AnnouncementRetrievalBg from "@/assets/image/announcement_retrieval-bg2.jpg";
import InformationRetrievalBg from "@/assets/image/information_retrieval-bg2.jpg";
import EmpiricalModelBg from "@/assets/image/empirical_model-bg2.jpg";
import FactorConversion from "@/assets/image/factor_conversion.jpg";
import Features from "@/assets/image/features.jpg";
import MyModel from "@/assets/image/my_model.jpg";
import ProgrammeAnnouncement from "@/assets/image/programme_announcement.jpg";



export const routes = [
    //
    {
        path: '/login',
        name: 'login',
        component: () =>
            import('@/views/user/login.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () =>
            import('@/views/test.vue')
    },
    {
        path: '/',
        name: 'home',
        component: () =>
            import('@/views/home.vue'),
        redirect: '/text_library',
        children: [
            // 公告检索
            {
                path: '/announcement_retrieval',
                name: 'announcement_retrieval',
                component: () =>
                    import('@/views/announcement_retrieval.vue'),
                meta: {
                    title: '公告检索',
                    img: AnnouncementRetrievalBg,
                }
            },
            {
                path: '/announcement_SHJ',
                name: 'announcement_SHJ',
                component: () =>
                    import('@/views/announcement_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: AnnouncementRetrievalBg,
                }
            },
            {
                path: '/announcement_Third',
                name: 'announcement_Third',
                component: () =>
                    import('@/views/announcement_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: AnnouncementRetrievalBg,
                }
            },
            {
                path: '/announcement_Bond',
                name: 'announcement_Bond',
                component: () =>
                    import('@/views/announcement_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: AnnouncementRetrievalBg,
                }
            },
            {
                path: '/announcement_HongKongShares',
                name: 'announcement_HongKongShares',
                component: () =>
                    import('@/views/announcement_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: AnnouncementRetrievalBg,
                }
            },
            {
                path: '/announcement_Fund',
                name: 'announcement_Fund',
                component: () =>
                    import('@/views/announcement_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: AnnouncementRetrievalBg,
                }
            },
            {
                path: '/announcement_Supervise',
                name: 'announcement_Supervise',
                component: () =>
                    import('@/views/announcement_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: AnnouncementRetrievalBg,
                }
            },
            // 资讯检索
            {
                path: '/information_retrieval',
                name: 'information_retrieval',
                component: () =>
                    import('@/views/information_retrieval.vue'),
                meta: {
                    title: '资讯检索',
                    img: InformationRetrievalBg,
                }
            },
            {
                path: '/information_A',
                name: 'information_A',
                component: () =>
                    import('@/views/information_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: InformationRetrievalBg,
                }
            },
            {
                path: '/information_F',
                name: 'information_F',
                component: () =>
                    import('@/views/information_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: InformationRetrievalBg,
                }
            },
            {
                path: '/information_D',
                name: 'information_D',
                component: () =>
                    import('@/views/information_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: InformationRetrievalBg,
                }
            },
            {
                path: '/information_Z',
                name: 'information_Z',
                component: () =>
                    import('@/views/information_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: InformationRetrievalBg,
                }
            },
            {
                path: '/information_C',
                name: 'information_C',
                component: () =>
                    import('@/views/information_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: InformationRetrievalBg,
                }
            },
            {
                path: '/information_R',
                name: 'information_R',
                component: () =>
                    import('@/views/information_retrieval/index.vue'),
                meta: {
                    title: '深沪京',
                    img: InformationRetrievalBg,
                }
            },
            // 特征提取
            {
                path: '/features',
                name: 'features',
                component: () =>
                    import('@/views/features/index.vue'),
                meta: {
                    title: '特征提取',
                    img: Features,
                }
            },
            {
                path: '/features_relation',
                name: 'features_relation',
                component: () =>
                    import('@/views/features/index.vue'),
                meta: {
                    title: '特征提取',
                    img: Features,
                }
            },
            {
                path: '/features_event',
                name: 'features_event',
                component: () =>
                    import('@/views/features/index.vue'),
                meta: {
                    title: '特征提取',
                    img: Features,
                }
            },
            //因子转换
            {
                path: '/factor_conversion',
                name: 'factor_conversion',
                component: () =>
                    import('@/views/factor_conversion/index.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: FactorConversion,
                }
            },

            // 实证模型
            {
                path: '/empirical_model',
                name: 'empirical_model',
                component: () =>
                    import('@/views/empirical_model.vue'),
                meta: {
                    title: '实证模型',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/empirical_model_b',
                name: 'empirical_model_b',
                component: () =>
                    import('@/views/empirical_model_b.vue'),
                meta: {
                    title: '实证模型',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/accounting_issues_page',
                name: 'accounting_issues_page',
                component: () =>
                    import('@/views/empirical_model/page.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/accounting_issues_echarts',
                name: 'accounting_issues_echarts',
                component: () =>
                    import('@/views/empirical_model/echarts_list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/accounting_issues_list',
                name: 'accounting_issues_list',
                component: () =>
                    import('@/views/empirical_model/model_list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/accounting_issues_model',
                name: 'accounting_issues_model',
                component: () =>
                    import('@/views/empirical_model/model.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/accounting_research_list',
                name: 'accounting_research_list',
                component: () =>
                    import('@/views/empirical_model/list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/audit_research_list',
                name: 'audit_research_list',
                component: () =>
                    import('@/views/empirical_model/list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/managing_the_world_list',
                name: 'managing_the_world_list',
                component: () =>
                    import('@/views/empirical_model/list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/financial_research_list',
                name: 'financial_research_list',
                component: () =>
                    import('@/views/empirical_model/list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/economic_research_list',
                name: 'economic_research_list',
                component: () =>
                    import('@/views/empirical_model/list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/economic_management_list',
                name: 'economic_management_list',
                component: () =>
                    import('@/views/empirical_model/list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/other_list',
                name: 'other_list',
                component: () =>
                    import('@/views/empirical_model/list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/internal_controls',
                name: 'internal_controls',
                component: () =>
                    import('@/views/empirical_model/model_list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/local_industrial_policies_list',
                name: 'local_industrial_policies_list',
                component: () =>
                    import('@/views/empirical_model/model_list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/M10',
                name: 'M10',
                component: () =>
                    import('@/views/empirical_model/model_list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/M100',
                name: 'M100',
                component: () =>
                    import('@/views/empirical_model/model_list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            {
                path: '/M500',
                name: 'M500',
                component: () =>
                    import('@/views/empirical_model/model_list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: EmpiricalModelBg,
                }
            },
            // 实证模型
            {
                path: '/intelligent_demonstration',
                name: 'intelligent_demonstration',
                component: () =>
                    import('@/views/intelligent_demonstration.vue'),
                meta: {
                    title: '实证模型',
                    img: EmpiricalModelBg,
                }
            },
            // word模型
            {
                path: '/model',
                name: 'model',
                component: () =>
                    import('@/views/my_model/model.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: MyModel,
                }
            },
            {
                path: '/model_list',
                name: 'model_list',
                component: () =>
                    import('@/views/my_model/model_list.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: MyModel,
                }
            },
            {
                path: '/model2',
                name: 'model2',
                component: () =>
                    import('@/views/my_model/model2.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: MyModel,
                }
            },
            {
                path: '/model2_list',
                name: 'model2_list',
                component: () =>
                    import('@/views/my_model/model_list2.vue'),
                meta: {
                    title: '会计基本问题实证模型库',
                    img: MyModel,
                }
            },
            // 方案管理
            {
                path: '/programme_announcement',
                name: 'programme_announcement',
                component: () =>
                    import('@/views/programme/announcement.vue'),
                meta: {
                    title: '公告方案',
                    img: ProgrammeAnnouncement,
                }
            },
            {
                path: '/programme_feature',
                name: 'programme_feature',
                component: () =>
                    import('@/views/programme/feature.vue'),
                meta: {
                    title: '特征方案',
                    img: ProgrammeAnnouncement,
                }
            },
            {
                path: '/programme_divisor',
                name: 'programme_divisor',
                component: () =>
                    import('@/views/programme/divisor.vue'),
                meta: {
                    title: '特征方案',
                    img: ProgrammeAnnouncement,
                }
            },
            {
                path: '/programme_info',
                name: 'programme_info',
                component: () =>
                    import('@/views/programme/info.vue'),
                meta: {
                    title: '特征方案',
                    img: ProgrammeAnnouncement,
                }
            },
            // 任务管理
            {
                path: '/task_announcement',
                name: 'task_announcement',
                component: () =>
                    import('@/views/task/announcement.vue'),
                meta: {
                    title: '公告任务',
                    img: ProgrammeAnnouncement,
                }
            },
            {
                path: '/task_feature',
                name: 'task_feature',
                component: () =>
                    import('@/views/task/feature.vue'),
                meta: {
                    title: '特征任务',
                    img: ProgrammeAnnouncement,
                }
            },
            {
                path: '/task_divisor',
                name: 'task_divisor',
                component: () =>
                    import('@/views/task/divisor.vue'),
                meta: {
                    title: '特征任务',
                    img: ProgrammeAnnouncement,
                }
            },
            {
                path: '/task_info',
                name: 'task_info',
                component: () =>
                    import('@/views/task/info.vue'),
                meta: {
                    title: '特征任务',
                    img: ProgrammeAnnouncement,
                }
            },
            // 用户
            {
                path: 'vip',
                name: 'vip',
                component: () =>
                    import('@/views/user/vip.vue'),
                meta: {
                    title: '特征任务',
                    img: ProgrammeAnnouncement,
                }
            },
            {
                path: 'info',
                name: 'info',
                component: () =>
                    import('@/views/user/info.vue'),
                meta: {
                    title: '特征任务',
                    img: ProgrammeAnnouncement,
                }
            },
            {
                path: 'password',
                name: 'password',
                component: () =>
                    import('@/views/user/password.vue'),
                meta: {
                    title: '特征任务',
                    img: ProgrammeAnnouncement,
                }
            },
            {
                path: 'agreement',
                name: 'agreement',
                component: () =>
                    import('@/views/user/agreement.vue'),
                meta: {
                    title: '特征任务',
                    img: ProgrammeAnnouncement,
                }
            },
            {
                path: 'agreement_file',
                name: 'agreement_file',
                component: () =>
                    import('@/views/user/agreement_file.vue'),
                meta: {
                    title: '特征任务',
                    img: ProgrammeAnnouncement,
                }
            },
            // 用户
            {
                path: 'text_library',
                name: 'text_library',
                component: () =>
                    import('@/views/text_library/index.vue'),
                meta: {
                    title: '特征任务',
                    img: ProgrammeAnnouncement,
                }
            },


        ]
    },
    {
        path: '/accounting_issues_echarts_only',
        name: 'accounting_issues_echarts_only',
        component: () =>
            import('@/views/accounting_issues_echarts_only.vue')
    },
    {
        path: '/echarts_board',
        name: 'echarts_board',
        component: () =>
            import('@/views/echarts_board.vue')
    },


]