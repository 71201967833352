import { defineStore } from 'pinia'
import ajax from "@/ajax";

var date = new Date()
var m = date.getMonth() + 1
var d = date.getDate()
var now = date.getFullYear() + "/" + (m < 10 ? "0" + m : m) + "/" + (d < 10 ? "0" + d : d)
if(now != localStorage.date &&!ajax.get_url_argument_obj().loginnum){
	localStorage.user = ""
}

export const storeInit = defineStore({
    // id 是必填的，并且所有 Store 中唯一。因为Pinia会将它在devtools显示
    id: 'store',
    state() {
        return {
            user: JSON.parse(now == localStorage.date ? (localStorage.user || "{}") : "{}"),
            re_load:true,
            num:{}
        }
    },
    getters: {},
    actions: {}
})